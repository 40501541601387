import React from "react"

import Layout from "../../components/layout"
import EventList from "../../components/EventList"

const EventsPage = () => {
  return (
    <Layout>
      <EventList />
    </Layout>
  )
}

export default EventsPage
